import React, { useState, useRef } from "react";
import "./dynamicCheckService.css";
import "react-phone-number-input/style.css";
import { Dialog, DialogTitle } from "@mui/material";
import { checkImei } from "../../_services/unlockPhone";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLoading } from "../../_shared/LoadingContext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReCAPTCHA from "react-google-recaptcha";
import Axios from "axios";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const DynamicCheckService = () => {
  const { t } = useTranslation();
  const { checkService } = useParams(); // Get dynamic route parameter
  const [openDialog, handleDisplay] = useState(false);
  const [info, setInfo] = useState(null);
  const { startLoading, stopLoading } = useLoading();
  const [valid_token, setValidToken] = useState([]);
  const captchaRef = useRef(null);

  const SECRET_KEY = "6LcjP2spAAAAAPk0RY2IdFqOEDGz0OwxoXALjjbS";
  const SITE_KEY = "6LcjP2spAAAAAGEdDTXPvavTKTSHVq2lo5jItpk8";

  const handleClose = () => {
    handleDisplay(false);
  };

  const openDialogBox = () => {
    handleDisplay(true);
  };

  const Imei = Yup.object().shape({
    imei: Yup.string()
      .required(t('IEMIE1'))
      .test("isValidIMEI", t('IEMIE2'), (value) => isValidIMEI(value)),
  });

  const isValidIMEI = (imei) => {
    if (/^\d{15}$/.test(imei)) {
      const imeiDigits = imei.split("").map(Number);
      const checksum = imeiDigits.pop();
      const sum = imeiDigits.reduce((acc, digit, index) => {
        const doubledDigit = index % 2 === 0 ? digit : digit * 2;
        return acc + (doubledDigit > 9 ? doubledDigit - 9 : doubledDigit);
      }, 0);
      return (10 - (sum % 10)) % 10 === checksum;
    }
    return false;
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      let token = captchaRef.current.getValue();
      captchaRef.current.reset();

      if (token) {
        startLoading();
        let valid_token = await verifyToken(token);
        setValidToken(valid_token);

        if (valid_token[0].success === true) {
          const res = await checkImei(values.imei);
          if (res) {
            setInfo(res);
            openDialogBox();
            resetForm();
          } else {
            console.error("Unexpected response format:", res);
          }
        } else {
          toast.error("Sorry!! Verify you are not a bot");
        }
      } else {
        toast.error("Sorry!! Verify you are not a bot");
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
      toast.error('Something went wrong');
    } finally {
      stopLoading();
    }
  };

  const verifyToken = async (token) => {
    let APIResponse = [];

    try {
      let response = await Axios.post(`${process.env.REACT_APP_API_URL}/verifyToken`, {
        reCAPTCHA_TOKEN: token,
        Secret_Key: SECRET_KEY,
      });
      APIResponse.push(response["data"]);
      toast.success(t('IEMIS1'));
      return APIResponse;
    } catch (error) {
      console.log(error);
    }
  };

  // Dynamic data rendering based on CheckService parameter
  const data = {};

  switch (checkService) {
    case "premium-blacklist-checker":
      data.url = 'premium-blacklist-checker';
      data.title = 'Premium blacklist check';
      data.subtitle = "Verify if your phone is blacklisted or clean using its IMEI number. Ensure your device is not blacklisted due to loss, theft, or unpaid bills.";
      break;
    case "apple-full-info-check-gsx":
      data.url = 'apple-full-info-check-gsx';
      data.title = 'Apple full info check (GSX Report)';
      data.subtitle = "The iPhone GSX (Global Service Exchange) Report provides detailed information about an iPhone's status and history, including warranty details, repairs, and technical issues. It helps verify device authenticity and assess its condition for repairs or purchases.";
      break;
    case "samsung-account-removal":
      data.url = 'samsung-account-removal';
      data.title = 'Samsung Account Removal (FRP)';
      data.subtitle = "Remove your Samsung account effortlessly to regain full control.";
      break;
    // Add more cases for other services
    default:
      data.url = 'default-service';
      data.title = 'Default Service';
      data.subtitle = "This is a default service description.";
  }
  console.log(data)
  return (
    <div>
      <section className="banner-ImeiBlacklist py-md-2 pt-5 pb-0 position-relative">
        <div className="container py-2 pt-md-4">
          <div className="row justify-content-center p-3">
            <div className="col-md-6 col-12 px-md-5">
              <h1 className="fw-bold display-5 mt-md-5">
              {data.title}
              </h1>
              <p className=" imeiblacklist text-justify m-0">
                {data.subtitle}
              </p>
            </div>
            <div className="col-11 col-md-6">
              <Formik
                initialValues={{
                  imei: "",
                }}
                validationSchema={Imei}
                onSubmit={handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <>
                    <Form className="bg-white rounded p-4">
                      <div className="card unlock-card bg-transparent border-0 my-md-0 my-1">
                        <div className="card-body">
                          <h4 className="fw-semibold mb-4">Fill up the form</h4>
                          <div>
                            <p className="mb-0 fw-semibold">IMEI (15 digits)</p>
                            <div className="input-group border d-flex align-items-center p-2 rounded mb-3 bg-white w-100">
                              <label
                                className="input-group-text bg-transparent border-0"
                                htmlFor="inputGroupSelect01"
                              >
                                <img
                                  loading="lazy"
                                  src="/images/ph_phone-call (1).svg"
                                  alt=""
                                /><span class="mx-2mx-0 text-secondary"> | </span>
                              </label>
                              <Field
                                type="text"
                                className="form-control border-0 py-1"
                                placeholder={t('IEMIPC1')}
                                aria-label={t('IEMIPC1')}
                                id="imei"
                                name="imei"
                                value={values.imei}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div>
                            <p className="mb-0 fw-semibold">Email</p>
                            <div className="input-group border d-flex align-items-center p-2 rounded mb-3 bg-white w-100">
                              <label
                                className="input-group-text bg-transparent border-0"
                                htmlFor="inputGroupSelect01"
                              >
                                <img
                                  loading="lazy"
                                  src="/images/at-sign2.svg"
                                  alt=""
                                /><span class="mx-2mx-0 text-secondary"> | </span>
                              </label>
                              <Field
                                type="email"
                                className="form-control border-0 py-1"
                                placeholder="Enter your email address"
                                aria-label={t('IEMIPC1')}
                                id="imei"
                                name="imei"
                                value={values.imei}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <ErrorMessage
                          name="imei"
                          component="div"
                          className="error-message ps-5 ms-4"
                        />
                          <button
                            type="submit"
                            className="btn btn-lg mt-md-0 mt-2 w-100 unlock-btn"
                          >
                           {t('IEMIB1')}
                          </button>
                        </div>
                     
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
              <div>
                <ReCAPTCHA
                  className="recaptcha"
                  sitekey={SITE_KEY}
                  ref={captchaRef}
                />
              </div>
            </div>
          </div>
        </div>
        <Dialog className="dialog" open={openDialog}>
          <div className="text-center">
            <img loading="lazy"  src="/images/unlock-logo.png" alt="" />
            <span className="float-end mt-3" onClick={handleClose}>
            <img
                  loading="lazy"
                  src="/images/close-dialog.svg"
                  alt="close"
                  style={{ cursor: "pointer" }}
                />
            </span>
          </div>

          <DialogTitle className="title">
          {t('IEMIH3')}
          </DialogTitle>
          <div className="row d-flex justify-content-between">
            <div className="col-5 text-start pt-3">
              <span className="">IMEI</span>
            </div>
            <div className="col-7 text-end pt-3">
              <span className="">8539375828501345</span>
            </div>
            <div className="col-6 text-start pt-3">
              <span className="">Find My iPhone:</span>
            </div>
            <div className="col-6 text-end pt-3">
              <span className="">ON</span>
            </div>
            <div className="col-6 text-start pt-3">
              <span className="">iCloud Status:</span>
            </div>
            <div className="col-6 text-end pt-3">
              <span className="text-success">Clean</span>
            </div>
            <div className="text-end d-flex justify-content-md-end">
              <button className="btn btn-sm mt-5 btn1">
                Check Carrier / Country
              </button>
              &nbsp;&nbsp;
              <button className="btn btn-sm mt-5 btn2">
                Buy iCloud Removed
              </button>
            </div>
          </div>
        </Dialog>
      </section>
    </div>
  );
};

export default DynamicCheckService;
