import React from "react";
import { useTranslation } from "react-i18next";
import "./aboutus.css";
import { Link } from "react-router-dom";
import Helper from "../NewHowitWork/Helper";

const AboutUs = () => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <section className="banner py-3 About-page">
        <div className="container py-md-2 pt-5">
          <div className="row py-md-2 justify-content-center align-items-center">
            <div className="col-12 col-md-5 px-4">
              <h1 className="fw-semibold display-4">
                {t("abh1")}
              </h1>
              <p className=" imeiblacklist balance">
                {" "}
                {t("abp1")}
              </p>
              <button class="btn w-25 check-now-btn fw-semibold mb-0">Unlock Now</button>
            </div>
            <div className="col-12 col-md-5 px-4 text-center">
              <img src="/images/about-banner-img.png" width={'500'} />
            </div>
          </div>
        </div>
      </section>
      <section className="about-our-story">
        <div className="container p-3 py-md-5">
          <div className="row py-md-2 py-5">
            <div className="col-12 px-0">
              <h2 className="text-center choose-unlockriver fw-semibold balance ">
                {t("abp2")}
              </h2>
            </div>
          </div>
          <div className="row justify-content-center d-flex py-md-5 align-items-center">
            <div className="col-12 col-md-6">
              <h2 className="display-5 fw-bold">{t("abh2")}</h2>
              <p className=" balance py-2">{t("abp3")}</p>
              <Link
                className="text-decoration-none learn-more fw-semibold"
                onClick={scrollToTop}
              >
                {t("abb1")} <i className="fa fa-arrow-right ms-2"></i>
              </Link>
            </div>
            <div className="col-10 col-md-6 text-center py-4 py-md-0">
              <img
                className="img-fluid"
                src="/images/about-story.png"
                alt="about-story"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="about-mission">
        <div className="container p-3 py-md-3">
          <div className="row justify-content-center d-flex  align-items-center">
            <div className="col-10 col-md-6 text-center py-4 py-md-0 mission-img">
              <img
                className="img-fluid p-4 p-md-0"
                src="/images/about-mission.png"
                alt="about-mission"
              />
            </div>
            <div className="col-12 col-md-6 px-md-5 mission-col">
              <h2 className="display-5 fw-bold">{t('abh3')}</h2>
              <p className=" balance py-2">{t("abp4")}</p>
              <Link
                className="text-decoration-none learn-more fw-semibold"
                onClick={scrollToTop}
              >
                {t("abb1")}
                <i className="fa fa-arrow-right ms-2"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Helper title={t("abh4")} />
    </>
  );
};

export default AboutUs;
