import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./newHowitWork.css";

const Helper = ({ title }) => {
  const { t } = useTranslation();

  return (
    <>
      <section className="why-choose-us">
        <div className="container p-3 py-md-5">
          <div className="row justify-content-center d-flex  align-items-center">
            <div className="col-12 col-md-6 px-3 px-md-0">
              <h2 className="display-5 fw-bold">{title}</h2>
              <ul className="choose-us-list py-4">
                <li className="ps-2 list-title pb-4">
                  <span className="imeiblacklist fw-bold pb-2">
                    {t("hlph1")}
                  </span>
                  <p className="pt-2">{t("hlpp1")}</p>
                </li>
                <li className="ps-2 list-title pb-4">
                  <span className="imeiblacklist fw-bold">{t("hlph2")}</span>
                  <p className="pt-2">{t("hlpp2")}</p>
                </li>
                <li className="ps-2 list-title pb-4">
                  <span className="imeiblacklist fw-bold">{t("hlph3")}</span>
                  <p className="pt-2">{t("hlpp3")}</p>
                </li>
                <li className="ps-2 list-title pb-4">
                  <span className="imeiblacklist fw-bold">{t("hlph4")}</span>
                  <p className="pt-2">{t("hlpp4")}</p>
                </li>
              </ul>
            </div>

            <div className="col-10 col-md-6 text-center py-4 py-md-0 ">
              <img
                className="img-fluid p-4 p-md-0"
                src="/images/Frame.png"
                alt="about-mission" width={'450'}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Helper;
