import React, { useEffect, useState } from "react";
import "./../header/header.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import VideoModal from "../../shared/videos/VideoModal";
import { useLoading } from "../../_shared/LoadingContext";
import {
  getAllCarrier,
  getManufacturerById,
  getModelById,
  getProductById,
} from "../../_services/unlockPhone";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import UnlockPhone from "../unlock-phone/UnlockPhone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import cookies from "js-cookie";
const Home = () => {
  const { t } = useTranslation();
  const country = cookies.get("i18next") || "en";
  const [carrierData, setCarrierData] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState([]);
  const [manufacturerData, setManufacturerData] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [openDialog, handleDisplay] = useState(false);
  const [formValue, setFormValue] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { startLoading, stopLoading } = useLoading();
  const openModal = () => {
    setIsModalOpen(true);
    document.body.classList.add("openModal");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.classList.remove("openModal");
  };
  const handleClose = () => {
    handleDisplay(false);
  };

  const openDialogBox = (x) => {
    handleDisplay(true);
    // x();
  };
  const unlockSchema = Yup.object().shape({
    carrier: Yup.string().required(t("ErrorField1")),
    manufacturer: Yup.string().required(t("ErrorField2")),
    model: Yup.string().required(t("ErrorField3")),
    imei: Yup.string()
      .required(t("ErrorField4"))
      .test("isValidIMEI", t("ErrorField41"), (value) => {
        return value && value.length === 15;
      }),
    email: Yup.string().email(t("ErrorField51")).required(t("ErrorField5")),
    phone: "",
  });

  useEffect(() => {
    startLoading();
    getAllCarrier().then((res) => {
      if (country === "es") {
        const spainItems = res.filter((item) =>
          item.name.toLowerCase().includes("spain")
        );
        const otherItems = res.filter(
          (item) => !item.name.toLowerCase().includes("spain")
        );
        const sortedArray = [...spainItems, ...otherItems];
        console.log(sortedArray);
        setCarrierData(sortedArray);
      } else {
        console.log(res)
        setCarrierData(res);
      }
    });
    stopLoading();
  }, []);

  const handleCarrierChange = async (selectedOption, handleChange) => {
    try {
      startLoading();
      handleChange("carrier")(selectedOption.value.name);
      setSelectedCarrier(selectedOption.value.manufacturer_id);
      const res = await getManufacturerById(
        selectedOption.value.manufacturer_id
      );
      setManufacturerData(res);
      handleChange("manufacturer")("");
      handleChange("model")("");
    } catch (error) {
      console.error("Error in handleCarrierChange:", error);
    } finally {
      stopLoading();
    }
  };
  const handleManufacturerChange = async (selectedOption, handleChange) => {
    try {
      startLoading();
      handleChange("manufacturer")(selectedOption.value.text);
      const res = await getModelById(
        selectedCarrier,
        selectedOption.value.value
      );
      setModelData(res);
      handleChange("model")("");
    } catch (error) {
      console.error("Error in handleManufacturerChange:", error);
    } finally {
      stopLoading();
    }
  };
  const handleModelChange = async (selectedOption, handleChange) => {
    try {
      startLoading();
      handleChange("model")(selectedOption.value.text);
      const res = await getProductById(selectedOption.value.value);
      setProductData(res);
    } catch (error) {
      console.error("Error in handleModelChange:", error);
    } finally {
      stopLoading();
    }
  };
  const isValidIMEI = (imei) => {
    if (/^\d{15}$/.test(imei)) {
      const imeiDigits = imei.split("").map(Number);
      const checksum = imeiDigits.pop();
      const sum = imeiDigits.reduce((acc, digit, index) => {
        const doubledDigit = index % 2 === 0 ? digit : digit * 2;
        return acc + (doubledDigit > 9 ? doubledDigit - 9 : doubledDigit);
      }, 0);
      return (10 - (sum % 10)) % 10 === checksum;
    }

    return false;
  };
  const handleSubmit = async (values, { resetForm, isValid, touched }) => {
    try {
      console.log(values);
      setFormValue(values);
      console.log();
      if (isValidIMEI(values.imei)) {
        console.log("submitted");
        startLoading();
        setTimeout(() => {
          stopLoading();
          openDialogBox(resetForm); // Open dialog box after loading stops
          // resetForm(); // Reset the form
        }, 100);
      } else {
        toast.error("Invalid IMEI Number");
      }
    } catch (error) {
      console.error("Error handling form submission:", error);
      toast.error(error.message);
    }
  };
  return (
    <div>
      <section className="banner home py-md-4 position-relative">
        <div className="container py-md-2 pt-5 pb-1 px-3 px-md-0">
          <div className="row p-md-5">
            <div className="col-md-12 pt-3 px-md-5 pt-md-0 col-lg-7">
              <h1 className="fw-bold display-4 mt-5 mt-md-2">
                {t("Home11")}
                <br />
                {t("Home12")}
              </h1>
              <h2 className="my-3 globally-connect pt-2 pb-md-4 border-bottom">{t("Home2")}</h2>
              <ul className="p-0 d-none d-md-block mt-md-4">
                <li className="list-group-item h6 lh-lg mb-0">{t("Home3")}</li>
                <li className="list-group-item h6 lh-lg mb-0">
                  {/* <span className="fw-bold">No</span> risk of damaging your
                  phone */}
                  {t("Home4")}
                </li>
                <li className="list-group-item h6 lh-lg mb-0">{t("Home5")}</li>
                <li className="list-group-item h6 lh-lg mb-0">{t("Home6")}</li>
                <li className="list-group-item h6 lh-lg mb-0">{t("Home7")}</li>
              </ul>
              <button
                type="button"
                className="btn banner-button mb-3 d-none d-md-block"
                onClick={openModal}
              >
                <i className="far fa-play-circle"></i> {t("Button1")}
              </button>
              <VideoModal isOpen={isModalOpen} onClose={closeModal} />
            </div>
            <div className="col-md-12 col-lg-5 card-form">
              <div className="card unlock-card ">
                <div className="card-body">
                  <h3 className="lh-lg fw-semibold">{t("FormHead1")}</h3>
                  <Formik
                    initialValues={{
                      carrier: "",
                      manufacturer: "",
                      model: "",
                      imei: "",
                      email: "",
                      phone: "",
                    }}
                    validationSchema={unlockSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isValid,
                    }) => (
                      <>
                        <Form>
                          <ErrorMessage
                            name="carrier"
                            component="div"
                            style={{ color: "red" }}
                          />
                          <div className="input-group mb-3 border rounded-input d-flex justify-content-between">
                            <label
                              className="input-group-text border-0 bg-transparent"
                              htmlFor="carrier"
                            >
                              <img
                                loading="lazy"
                                src="/images/carrier.svg"
                                alt=""
                                width={'20'}
                                />
                                <span class="mx-2mx-0 text-secondary"> | </span>
                            </label>
                            <Select
                              className="form-select-carrier border-0"
                              id="carrier"
                              name="carrier"
                              options={carrierData?.map((carrier) => ({
                                value: carrier,
                                label: carrier.name,
                              }))}
                              value={carrierData?.find(
                                (option) => option.value === values.carrier
                              )}
                              onChange={(selectedOption) => {
                                handleCarrierChange(
                                  selectedOption,
                                  handleChange
                                );
                              }}
                              onBlur={handleBlur}
                              isSearchable={true}
                              filterOption={(option, inputValue) => {
                                const optionLabel = option.label.toLowerCase();
                                const searchValue = inputValue.toLowerCase();
                                return optionLabel.includes(searchValue);
                              }}
                              placeholder={t("FormField1")}
                            />
                            <Tooltip
                              title={t("FormField1Help")}
                              placement="top-end"
                            >
                              <IconButton>
                                <HelpIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <ErrorMessage
                            name="manufacturer"
                            component="div"
                            style={{ color: "red" }}
                          />
                          <div className="input-group mb-3 border rounded-input d-flex justify-content-between">
                            <label
                              className="input-group-text border-0 bg-transparent"
                              htmlFor="carrier"
                            >
                              <img
                                loading="lazy"
                                src="/images/manufacturer.svg"
                                alt=""
                                width={'20'}
                                />
                                <span class="mx-2mx-0 text-secondary"> | </span>
                            </label>
                            <Select
                              className="form-select-carrier border-0"
                              id="manufacturer"
                              name="manufacturer"
                              options={manufacturerData?.map(
                                (manufacturer) => ({
                                  value: manufacturer,
                                  label: manufacturer.text,
                                })
                              )}
                              value={manufacturerData?.find(
                                (option) => option.value === values.manufacturer
                              )}
                              onChange={(selectedOption) => {
                                handleManufacturerChange(
                                  selectedOption,
                                  handleChange
                                );
                              }}
                              onBlur={handleBlur}
                              isSearchable={true}
                              filterOption={(option, inputValue) => {
                                const optionLabel = option.label.toLowerCase();
                                const searchValue = inputValue.toLowerCase();
                                return optionLabel.includes(searchValue);
                              }}
                              placeholder={t("FormField2")}
                            />
                            <Tooltip
                              title={t("FormField2Help")}
                              placement="top-end"
                            >
                              <IconButton>
                                <HelpIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        <div className="d-md-flex gap-3 model-imei">
                            <ErrorMessage
                              name="model"
                              component="div"
                              style={{ color: "red" }}
                            />
                            <div className="input-group mb-3 border rounded-input d-flex justify-content-between">
                              <label
                                className="input-group-text py-0 ps-2 pe-0 border-0 bg-transparent"
                                htmlFor="inputGroupSelect01"
                              >
                                <img
                                  loading="lazy"
                                  src="/images/ph_phone-call.svg"
                                  alt="ph_phone-call"
                                  width={'20'}
                                />
                                <span class="mx-2mx-0 text-secondary"> | </span>
                              </label>
                              <Select
                                className="form-select-carrier border-0"
                                id="model"
                                name="model"
                                options={modelData?.map((model) => ({
                                  value: model,
                                  label: model.text,
                                }))}
                                value={modelData?.find(
                                  (option) => option.value === values.model
                                )}
                                onChange={(selectedOption) => {
                                  handleModelChange(selectedOption, handleChange);
                                }}
                                onBlur={handleBlur}
                                isSearchable={true}
                                filterOption={(option, inputValue) => {
                                  const optionLabel = option.label.toLowerCase();
                                  const searchValue = inputValue.toLowerCase();
                                  return optionLabel.includes(searchValue);
                                }}
                                placeholder={t("FormField3")}
                              />
                              <Tooltip
                                title={t("FormField3Help")}
                                placement="top-end"
                              >
                                <IconButton>
                                  <HelpIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                            
                            <div className="input-group mb-3 border rounded-input py-1 d-flex justify-content-between">
                              <label
                                className="input-group-text border-0 bg-transparent"
                                htmlFor="inputGroupSelect01"
                              >
                                <img
                                  loading="lazy"
                                  src="/images/ph_phone-call (1).svg"
                                  alt=""
                                />
                               <span class="mx-2mx-0 text-secondary"> | </span>
                              </label>
                              <Field
                                type="text"
                                className="form-control border-0 py-2"
                                placeholder={t("FormField4")}
                                aria-label="IMEI (15 digits)"
                                id="imei"
                                name="imei"
                                value={values.imei}
                                onChange={handleChange}
                              />
                              <Tooltip
                                title={t("FormField4Help")}
                                placement="top-end"
                              >
                                <IconButton>
                                  <HelpIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                          </div>
                          <ErrorMessage
                              name="imei"
                              component="div"
                              style={{ color: "red" }}
                            />
                          
                          <div className="input-group mb-3 border rounded-input py-1 d-flex justify-content-between">
                            <label
                              className="input-group-text border-0 bg-transparent"
                              htmlFor="inputGroupSelect01"
                            >
                              <img
                                loading="lazy"
                                src="/images/at-sign2.svg" 
                                alt=""
                                width={'20'}
                                />
                              </label>
                            <Field
                              type="email"
                              className="form-control border-0 py-2"
                              placeholder={t("FormField5")}
                              aria-label="Email"
                              id="email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                            />
                            <Tooltip
                              title={t("FormField5Help")}
                              placement="top-end"
                            >
                              <IconButton>
                                <HelpIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <ErrorMessage
                            name="email"
                            component="div"
                            style={{ color: "red" }}
                          />
                          <div className="input-group mb-3 border rounded-input py-1 d-flex justify-content-between">
                            <PhoneInput
                              placeholder={t("FormField6")}
                              country={country === "en" ? "us" : "es"}
                              autoFormat
                              value={values.phone}
                              onChange={(phone) => handleChange("phone")(phone)}
                              onBlur={handleBlur("phone")}
                            />

                            <Tooltip
                              title={t("FormField6Help")}
                              placement="top-end"
                            >
                              <IconButton>
                                <HelpIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <button
                            type="submit"
                            className={`btn btn-lg w-100 unlock-btn`}
                            // onClick={() => {
                            //   handleSubmit(values);
                            //   if (isValid && Object.keys(touched).length > 0) {
                            //     console.log("submitted");
                            //     startLoading();
                            //     setTimeout(() => {
                            //       stopLoading();
                            //     }, 1000);
                            //     openDialogBox();
                            //   }
                            // }}
                          >
                            {t("Button2")}
                          </button>
                        </Form>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
              <ul className="pt-3 px-0 d-block d-md-none">
                <li className="list-group-item h4 lh-lg mb-0">{t("Home3")}</li>
                <li className="list-group-item h4 lh-lg mb-0">
                  {/* <span className="fw-bold">No</span> risk of damaging your
                  phone */}
                  {t("Home4")}
                </li>
                <li className="list-group-item h4 lh-lg mb-0">{t("Home5")}</li>
                <li className="list-group-item h4 lh-lg mb-0">{t("Home6")}</li>
                <li className="list-group-item h4 lh-lg mb-0">{t("Home7")}</li>
              </ul>
              <button
                type="button"
                className="btn btn-outline-line banner-button mb-3 d-block d-md-none"
                onClick={openModal}
              >
                <i className="far fa-play-circle"></i> {t("Button1")}
              </button>
              <VideoModal isOpen={isModalOpen} onClose={closeModal} />
            </div>
          </div>
        </div>
        <Dialog className="dialog dialog-box" open={openDialog}>
          <div className="text-center close-btn-cus">
            <span className="float-end mt-3" onClick={handleClose}>
              <img
                loading="lazy"
                src="/images/close-dialog.svg"
                alt="close"
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>

          <DialogTitle className="title">{t("UnpHead")}</DialogTitle>
          <UnlockPhone productData={productData} formValue={formValue} />
        </Dialog>
      </section>
    </div>
  );
};

export default Home;
