import React,{useState} from "react";
import { useTranslation } from "react-i18next";
import LinkIcon from "@mui/icons-material/Link";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import HelpIcon from "@mui/icons-material/Help";
import { Formik } from "formik";
import * as Yup from "yup";
import { requestRefund } from "../../_services/contact";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLoading } from "../../_shared/LoadingContext";
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: 'var(--Neutral-3, #343434)',
    maxWidth: 445,
    fontSize: "16px",
    border: "1px solid #dadde9",
  },
}));
const RequestRefund = () => {
  const { t } = useTranslation();
  const { startLoading, stopLoading } = useLoading();
  const [video, setVideo] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  const contactSchema = Yup.object().shape({
    email: Yup.string().email(t("ContE22")).required(t("ContE21")),
    description: Yup.string().required(t("rarE2")),
    
  });
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB (for example)
    if (file.size > maxSizeInBytes) {
      toast.error('File size should be less than 25MB.');
      return;
    }
    if (file) {
      setVideo( file );
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <section className="">
        <div className="container py-md-2 pt-2">
          <div className="row pt-md-5">
            <div className="col-12 col-md-7 px-4">
              <h2 className="tc-head display-5 fw-bold py-3">{t("rarh1")}</h2>
              <p className="tc-text my-2">{t("rarp1")}</p>
            </div>
            <div className="col-12 col-md-7 my-3 px-4">
              <div className="refund-note p-md-4 p-2 d-flex rounded">
                <i className="fa fa-exclamation text-white bg-exclamation rounded-circle"></i>
                <p className="tc-text my-0 mx-3"> {t("rarh2")}</p>
              </div>
            </div>
            <div className="col-12 col-md-7 pt-4 px-4">
              <Formik
                initialValues={{
                  email: "",
                  description: "",
                  video: "",
                }}
                validationSchema={contactSchema}
                onSubmit={(values, { resetForm }) => {
                  startLoading();
                  console.log(video);
                  const formData = new FormData();
                  formData.append("email", values.email);
                  formData.append("description", values.description);
                  formData.append("video",video);
                  requestRefund(formData)
                    .then((res) => {
                      console.log("Response:", res);
                      resetForm();
                      setVideo("");
                      stopLoading();
                      toast.success(res.message);
                    })
                    .catch((error) => {
                      stopLoading();
                      toast.error(error.message);
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <label className="fw-semibold">Email</label>
                    <input
                      type="email"
                      placeholder={t("rarf1")}
                      className="form-control border border-light mb-3 p-3"
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                    />
                    {errors.email && touched.email ? (
                      <span style={{ color: "red" }}>{errors.email}</span>
                    ) : null}
                    <label className="fw-semibold">Your message</label>
                    <textarea
                      className="form-control mb-3 p-3"
                      id="description"
                      name="description"
                      rows="4"
                      placeholder={t("rarf2")}
                      value={values.description}
                      onChange={handleChange}
                    ></textarea>
                    {errors.description && touched.description ? (
                      <span style={{ color: "red" }}>{errors.description}</span>
                    ) : null}
                    <div className="d-flex mb-3 py-2">
                      <LinkIcon
                        style={{
                          transform: "rotate(315deg)",
                          width: 24,
                          height: 24,
                          "margin-top": 10,
                          "margin-right": 10,
                        }}
                      />

                      <input
                        type="file"
                        className="form-control w-50"
                        id="video"
                        name="video"
                        placeholder={t("rarf3")}
                        value={video?.filename}
                        onChange={handleFileChange}
                      />
                      {/* <Tooltip title={t("rartp1")} placement="right">
                        <IconButton>
                          <HelpIcon />
                        </IconButton>
                      </Tooltip> */}
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <ul>
                            <p className="fw-bold">
                              {t("rartp1")}
                            </p>
                            </ul>
                            <ul class="ms-3">
                            <li>{t("rartp2")}</li> 
                            <li className="text-wrap">{t("rartp3")}</li>
                            </ul>
                          </React.Fragment>
                        }
                        placement="right"
                      >
                        <IconButton>
                          <HelpIcon 
                          style={{
                          width: 32,
                          height: 32,
                          "margin-left": 10,
                        }}/>
                        </IconButton>
                      </HtmlTooltip>
                      {errors.video && touched.video ? (
                        <span style={{ color: "red" }}>{errors.video}</span>
                      ) : null}
                    </div>
                    <button
                      type="submit"
                      className="btn btn-lg w-100 send-request-btn"
                    >
                      {t("rarb1")}
                    </button>
                  </form>
                )}
              </Formik>
            </div>
            <ToastContainer />
            <div className="col-12 col-md-7 pt-3 pt-md-5 px-4">
              <p className="tc-text my-2">{t("rarp2")}</p>
              <br />
              {/* <p className="tc-text my-2">{t("rarp3")}</p>
              <br /> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RequestRefund;
